module.exports = function (currentSound) {

    var newDiv = document.createElement("div");
    newDiv.classList.add('player');
     
    document.getElementById('playerContainer').appendChild(newDiv); 

    var barFrame = document.createElement("div");
    var bar = document.createElement("div");
    barFrame.classList.add('barFrame');
    bar.classList.add('bar');
    barFrame.appendChild(bar);
    newDiv.appendChild(barFrame);

    barFrame.addEventListener('click', function(ev) {
        var ratio = ev.offsetX / barFrame.offsetWidth;
        currentSound.currentTime = ratio * currentSound.duration;
    });

    var progress = document.createElement("div");
    progress.classList.add("progress");
    progress.innerHTML = "00:00 / --:--";
    newDiv.appendChild(progress);

    function animate() {
        var duration = Math.floor(currentSound.duration);
        var durMin = Math.floor(duration / 60);
        var durSec = duration - (durMin * 60);

        var time = Math.floor(currentSound.currentTime);
        var minutes = Math.floor(time / 60);
        var seconds = time - (minutes * 60);

        function str_pad_left(string,pad,length) {
            return (new Array(length+1).join(pad)+string).slice(-length);
        }

        var finalTime = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);

        progress.innerHTML = finalTime + ' / ' + str_pad_left(durMin,'0',2)+':'+str_pad_left(durSec,'0',2);

        var w = currentSound.currentTime / currentSound.duration * 100;

        bar.style.width = (w += "%");    
        requestAnimationFrame(animate);
    }       

    var playButton = document.createElement("div");
    playButton.classList.add("playCss");
    playButton.innerHTML = "";
    playButton.onclick = function play() {
        currentSound.play();                                   
        requestAnimationFrame(animate);
    };
    newDiv.appendChild(playButton);


    var pauseButton = document.createElement("div");
    pauseButton.classList.add("pauseCss");
    pauseButton.onclick = function pause() {        
        currentSound.pause();
    };
    newDiv.appendChild(pauseButton);

    var stopButton = document.createElement("div");
    stopButton.classList.add("stopCss");
    stopButton.onclick = function stop() {        
        currentSound.pause();
        currentSound.currentTime = 0;                    

    };
    newDiv.appendChild(stopButton); 

    currentSound.onended = function() {
        setTimeout(function() { currentSound.currentTime = 0; }, 5000);
    };

    return playButton;

}                          
