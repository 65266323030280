var objectValues = require('object-values')
var raw = require('bel/raw');

var footnotes = require('markdown-it-footnote');
const md = require('markdown-it')();
const {
    html5Media
} = require('markdown-it-html5-media');
var mdSub = require('markdown-it-sub');
md.use(footnotes);
md.use(html5Media);
md.use(mdSub);

var audioGUI = require('./player')
var html = require('choo/html')
var fa = require('../../assets/all.js')
var Page = require('nanopage')
var Muuri = require('muuri')
var onload = require('on-load')

var grid, audio, currentSound, playerExists = false;
var searchField;
var selectedItem;
var about;

global.Cookies = require('js-cookie')

module.exports = view

function view(state, emit) {

    var page = state.page || {}

    const ht = html `
        <body> 
       
       <div class='site'>
            <div class='options'>
                <div class='menuButton' id='aboutButton'></div>
                ${control()}
            </div>
    
       <div class='aboutText'></div>
       <div class='archiveContainer'>
       <div class='archive' id='archive'>
            ${pages()}
       </div>  

       </div>  
        <div class="Left" id="left">
            <div class='viewerContainer'>
                <div class='viewer' id='viewer'></div>
            </div>
        </div>  
        ${audio()}
    </body>`

    const uniqueIdentifier = 1
    onload(ht, function load () {

        createGrid();   
        var options1 = document.getElementById('aboutButton');
        var options2 = document.getElementById('archiveButton');

        options1.onclick = function () {
            options1.classList.add('menuselected');
            options2.classList.remove('menuselected');
            setView(about)();
        }

        options2.onclick = function () {      
            window.onresize = null;
            var selected = document.getElementsByClassName('selected')[0];
            if( typeof selected !== 'undefined') { selected.classList.remove('selected') };
             
            options1.classList.remove('menuselected');
            options2.classList.add('menuselected');
            var archive = document.getElementsByClassName('archiveContainer')[0];
            archive.style.display = 'block';                                
            archive.style.opacity = 0;    
            archive.style.transition = "0.5s opacity";
            setTimeout(function () { archive.style.opacity = 1},0); 
            var aboutText = document.getElementsByClassName('aboutText')[0];
            aboutText.style.display = 'none';               
            var viewer = document.getElementsByClassName('viewer')[0];
            viewer.style.display = 'none';  
            grid.refreshItems().layout();   
            history.pushState({
                html: "",
            }, '/', '/');

        }    

    }, function unload () {
    }, uniqueIdentifier)

    //onload(ht, createGrid);

    return ht 

    function pages() {
        var page = new Page(state)
        var pages;
        if( page().isHome == "true" ) { pages = page().children() } else { pages = page().parent().children(); };
        pages = pages.sortBy('order', 'asce')
        pages = pages.toArray();

        var textFunc = function(child) {
            var string = child.html == null;
            return html `
            <div class="item" name="${child.title ? child.title : ''}">

                <div class="item-content" onclick = "${setView(child)}">
                    ${ child.image ? 
                            raw('<img class="item-image" src=assets/' + child.image + ' alt="" onload=fullOpacity() />') : '' }

                    ${ child.title ? 
                            raw(
                                '<div class="item-text" onclick="${setView(child)}" >' +
                                md.render(child.title).replace('<p>','').replace('</p>','') + 
                                '<br></div>'
                            ) : ''
                    }
                </div> 

            </div>`
        };

        return pages.map(function(child) {
            return textFunc(child);
        })
    }

    function audio() {
        audio = new Audio();
        audio.id = 'audio';
        audio.controls = "controls";
        return html`<div class='playerContainer' id='playerContainer'>
            ${audio}
        </div>`             
    }

    function setView(child) {

        return function() { 

            var selected = document.getElementsByClassName('selected')[0];
            if( typeof selected !== 'undefined') { 
                selected.getElementsByClassName('item-content')[0].style.color = '';
                selected.classList.remove('selected')
            };

            var itemDates = document.getElementsByClassName('item-date');
            for (let e of itemDates ) { 
                if( child.url == ('/' + e.id)) {
                    e.parentNode.parentNode.classList.add("selected");
                };
            };

            var archive = document.getElementById('archive');
            var viewer = document.getElementById('viewer');
            var left = document.getElementById('left');
            var playerContainer = document.getElementById('playerContainer');
            var aboutText = document.getElementsByClassName('aboutText')[0];

            viewer.style.display = 'block';
            viewer.style.transition = "0s opacity";
            viewer.style.opacity = 0.0;

            fullOpacity(archive);

            viewer.style.color = child.fg ? child.fg : 'black' ; 
            viewer.style['background-color'] = child.bg ? child.bg : 'rgb(255 222 179)'; 
            viewer.style['font'] = child.font ? child.font : '16px Times New Roman'; 

            child.textWidth ? function(){
                document.documentElement.style.setProperty('--textWidth', child.textWidth);
            }() : function(){
                //document.documentElement.style.setProperty('--textWidth', 'calc(50vw - 4rem)');
                document.documentElement.style.setProperty('--textWidth', '80ch');
            }();

            child.imgWidth ? function(){
                document.documentElement.style.setProperty('--imgWidth', child.imgWidth);
            }() : function(){
                document.documentElement.style.setProperty('--imgWidth', '100%');
            }();

            var html = child.html.replace(/\.\//g, '/content' + child.url + '/').replace(/href="#(.*?)/g, `href="/#$1`);

                if( child.notitle == null) { 
                    if( child.title != null) { 
                    html = md.render(child.title).replace('<p>','<b>').replace('</p>','</b>') + html; 
                };
                };

                viewer.innerHTML = html;
                fullOpacity(viewer);

                var bib = viewer.getElementsByClassName("unnumbered");
                for (let element of bib ) { element.parentNode.removeChild(element); }

                var ref = viewer.getElementsByClassName("references");
                for (let element of ref ) { 
                    var parent = element.parentNode;
                    parent.removeChild(element);   
                    parent.appendChild(document.createElement('hr'));
                    parent.appendChild(element); 
                } 

            viewer.scrollTop = 0;

            history.pushState({
                html: html,
                bg: child.bg,
                fg: child.fg,
                font: child.font,
                title: child.title,
                url: child.url
            }, child.url, child.url);

            window.onpopstate = function(event) {
                viewer.innerHTML = "";
                viewer.innerHTML = event.state.html;
                viewer.style['background-color'] = event.state.bg ? event.state.bg : "white";
                viewer.style['color'] = event.state.fg ? event.state.fg : "black";
                viewer.style['font'] = event.state.font ? event.state.font : "19px Times New Roman";

                var selected = document.getElementsByClassName('selected')[0];
                if( typeof selected !== 'undefined') { selected.classList.remove('selected') };

                for (let e of itemDates ) { 
                    if( event.state.url == ('/' + e.id)) {
                        e.parentNode.parentNode.classList.add("selected");
                        e.parentNode.classList.add("selectedB");
                    };
                }; 

                    viewer.style.display = 'block';
                    archive.style.display = 'block';
                    archive.style.opacity = 1;
                    aboutText.style.display = 'none'; 

                if(event.state.url == '/') {     
                    viewer.style.display = 'none';  
                } else {
                    viewer.style.display = 'block';
                }                
                fixAudio();
                fullOpacity(viewer);
            };

            fixAudio(); 

            viewer.style.transition = "0.2s opacity";
            viewer.style.opacity = 1;    

            var options1 = document.getElementById('aboutButton');
            var options2 = document.getElementById('archiveButton');

        }
    }       

    function fullOpacity (div) {
        var images = div.getElementsByTagName('img'); 
        for (let element of images ) { 
            element.onload = function () {
                element.style.opacity = 1.0; 
            } 
        }; 
    }

    function fixAudio() {
        var audioSrcList = viewer.getElementsByTagName("audio");
        if (audioSrcList.length > 0) { 
            for (let audioSrc of audioSrcList) {    
                audioSrc.style.display = "none";  

                var audioButton = document.createElement('button');
                audioButton.classList.add('playButton');
                audioButton.innerHTML = "Ã¢ÂÂ¶&#xFE0E;";
                audioSrc.parentNode.insertBefore(audioButton,audioSrc);

                audioButton.onclick = function(){
                    document.getElementById('audio').src = audioSrc.src;
                    document.getElementById('audio').play();
                    var playerContainer = document.getElementById('playerContainer');
                    playerContainer.style.display = "flex";
                    setTimeout(function(){
                        playerContainer.style.opacity = 1.0;
                    },100);
                    currentSound = document.getElementById('audio');
                    currentSound.style.display = "none";

                    if(playerExists == false) {
                        var playButton = audioGUI(currentSound);
                        playerExists = true;
                        playButton.click();
                    };
                };   
            };
        };
    }   

    function createGrid() {
        grid = new Muuri(".archive", {
            dragEnabled: false,
            layoutDuration: 0,           
            visibleStyles: {
                opacity: '1',
                transform: 'scale(1)'
            },
            hiddenStyles: {
                opacity: '0',
                transform: 'scale(1)'
            },
        });

        setTimeout(function(){
            grid.refreshItems().layout();   
        },300);              

        setTimeout(function(){
            grid._settings.layoutDuration = 500;
        },600);

        setView(page)();

        var searchField = document.getElementsByClassName("searchField")[0];
        searchField.value = '';
        var searchFieldValue = searchField.value.toLowerCase();

        searchField.addEventListener('keyup', function () {
            var newSearch = searchField.value.toLowerCase();
            if (searchFieldValue !== newSearch) {
                searchFieldValue = newSearch;
                filter();
            }
        });

        function filter () {
            grid.filter(function (item) {
                var isSearchMatch; 
                var title = item.getElement().getAttribute('name');
                if(title !== null) { isSearchMatch = !searchFieldValue ? true : (title || '').toLowerCase().indexOf(searchFieldValue) > -1 } else { isSearchMatch = true };
                return isSearchMatch;
            });
        }
    }

    function control () {

        return html `
        <div class="controls cf"> 
        <input class="formControl searchField" type="text" autocomplete="off" name="search" placeholder="search..." />
        </div>
            `
    }     

}
