var choo = require('choo')
var nanocontent = require('nanocontent')
var css = require('sheetify')
css('./index.css')

var site = nanocontent.readSiteSync('../vassaContent/content', {})                                

Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );
 
site = Object.filter(site, o => (o.o !== undefined));
site = Object.filter(site, o => (o.o >= 2));

var app = choo()

app.use(require('./src/stores/content')(site))
if ( process.env.NODE_ENV.trim() === "production" ) {
app.route('/', require('./src/views/notfound'))
} else {
app.route('*', require('./src/views/notfound'))
}

if (module.parent) module.exports = app
else app.mount('body')
